import { Helmet } from "react-helmet";
import { Heading, Button, Img } from "../../components";
import Header from "../../components/Header";
import PaiementStack30liguedes from "../../components/PaiementStack30liguedes";
import Sidebar from "../../components/Sidebar";
import React, { Suspense } from "react";
import AdminGestionPageDistributeurNavbar from "components/AdminGestionPageDistributeurNavbar";

export default function WalletPage() {
  return (
    <>
      <Helmet>
        <title>ReCut</title>
        <meta name="description" content="Visionnage, téléchargement et utilisation des contenus mis à disposition sur la plateforme ReCut." />
      </Helmet>
      <div className="relative w-full">
        <div className="flex flex-col z-[5] gap-[27px]">
          <AdminGestionPageDistributeurNavbar />
          <div className="ml-[54px] flex w-[66%] md:w-[100%] items-center gap-2.5 md:ml-0 md:w-full md:p-5">
            <Sidebar />
            
            <div className="flex w-[54%] md:w-[100%] flex-col items-center justify-center gap-8 rounded-lg bg-gray-900 p-9 sm:p-5">
               <Heading size="headline_1" as="h1" className="w-[100%] self-end md:mr-0 text-center">
                 24 €
               </Heading>
               <Heading size="button_1_medium" as="h2" className="w-[94%] text-center leading-6 md:w-full md:p-5">
                 Avec 24€ tu peux acheter 25 secondes des Tuche, 3 secondes de la bataille du Gouffrre de Helm ou 15
                 secondes de The Voice.
               </Heading>
               <Button
                 color="deep_orange_600"
                 size="3xl"
                 shape="round"
                 leftIcon={<Img src="images/img_cart_default_white.svg" alt="cart" className="h-[24px] w-[24px]" />}
                 className="min-w-[350px] gap-2 font-inter font-medium sm:px-5"
             >
                 Recharger mon compte
               </Button>
             </div>
          </div>
        </div>
      </div>
    </>

    // <>
    //   <Helmet>
    //     <title>ReCut</title>
    //     <meta name="description" content="Visionnage, téléchargement et utilisation des contenus mis à disposition sur la plateforme ReCut." />
    //   </Helmet>
    //   <div className="w-full">
    //     <div className="flex flex-col items-center">
    //       <Header className="self-stretch" />
    //       <div className="mt-[27px] flex w-[64%] items-start justify-between gap-5 self-start md:w-full md:p-5">
    //         <Sidebar2 />
    //         <div className="flex w-[54%] flex-col items-center justify-center gap-8 rounded-lg bg-gray-900 p-9 sm:p-5">
    //           <Heading size="headline_1" as="h1" className="mr-[133px] self-end md:mr-0">
    //             24 €
    //           </Heading>
    //           <Heading size="button_1_medium" as="h2" className="w-[94%] text-center leading-6 md:w-full md:p-5">
    //             Avec 24€ tu peux acheter 25 secondes des Tuche, 3 secondes de la bataille du Gouffrre de Helm ou 15
    //             secondes de The Voice.
    //           </Heading>
    //           <Button
    //             color="deep_orange_600"
    //             size="3xl"
    //             shape="round"
    //             leftIcon={<Img src="images/img_cart_default_white.svg" alt="cart" className="h-[24px] w-[24px]" />}
    //             className="min-w-[350px] gap-2 font-inter font-medium sm:px-5"
    //           >
    //             Recharger mon compte
    //           </Button>
    //         </div>
    //       </div>
    //       <div className="container-xs mt-[47px] flex flex-col items-center gap-[65px] px-[366px] md:p-5 md:px-5 sm:gap-8">
    //         <Heading size="headline_3" as="h2">
    //           Forfaits
    //         </Heading>
    //         <div className="grid grid-cols-2 justify-center gap-[15px] gap-y-[20.08px] self-stretch md:grid-cols-1">
    //           <Suspense fallback={<div className="loader">Loading feed...</div>}>
    //             {data.map((d, index) => (
    //               <PaiementStack30liguedes {...d} key={"wallet" + index} />
    //             ))}
    //           </Suspense>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </>
  );
}
