import { Helmet } from "react-helmet";
import { SelectBox, Img, Text, Input } from "../../components";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import React from "react";
import AdminGestionPageDistributeurNavbar from "components/AdminGestionPageDistributeurNavbar";

const dropDownOptions = [
  { label: "Option1", value: "option1" },
  { label: "Option2", value: "option2" },
  { label: "Option3", value: "option3" },
];

export default function MesinfospersonnellesPage() {
  return (
    <>
      <Helmet>
        <title>ReCut</title>
        <meta name="description" content="Visionnage, téléchargement et utilisation des contenus mis à disposition sur la plateforme ReCut." />
      </Helmet>
      <div className="relative w-full">
        <div className="flex flex-col z-[5] gap-[27px]">
          <AdminGestionPageDistributeurNavbar />
          <div className="ml-[54px] flex w-[66%] items-start gap-2.5 md:ml-0 md:w-full md:p-5">
            <Sidebar />
            
            <div className="flex flex-1 flex-col items-center justify-center rounded-lg bg-gray-900 p-[50px] md:p-5">
              <Img
                src="images/img_ellipse_11.png"
                alt="image"
                className="h-[144px] w-[144px] rounded-[72px] object-cover"
              />
              <Text as="p" className="mt-[22px]">
                Modifier la photo de profil
              </Text>
              <div className="mt-[81px] flex flex-col gap-3.5 self-stretch">
                <div className="flex flex-col items-start gap-3 pt-1">
                  <Text as="p">Nom et prénom</Text>
                  <Input
                    shape="round"
                    name="input_one"
                    placeholder={`François Bégodeau`}
                    className="self-stretch border border-solid border-white-dark_night-300 sm:pr-5"
                  />
                </div>
                <div className="flex flex-col items-start gap-[13px]">
                  <Text as="p">Activité</Text>
                  <SelectBox
                    shape="round"
                    indicator={
                      <Img src="images/img_arrowdown_black_900_01.svg" alt="arrow_down" className="h-[24px] w-[24px]" />
                    }
                    name="arrowdown"
                    placeholder={`Critique de cinéma`}
                    options={dropDownOptions}
                    className="gap-px self-stretch border border-solid border-white-dark_night-300 sm:pr-5"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
