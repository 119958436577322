import AdminGestionPageDistributeurNavbar from "components/AdminGestionPageDistributeurNavbar";
import Footer from "components/Footer";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { isLogin } from "services/authServices";

export default function PrivacyPolicy() {
    const [afficherFooter, setAfficherFooter] = useState(true);
    useEffect(() => {
        window.scrollTo(0, 0);
        setAfficherFooter(isLogin())
    }, []);
    
  return (
    <>
      <Helmet>
        <title>ReCut</title>
        <meta name="description" content="Visionnage, téléchargement et utilisation des contenus mis à disposition sur la plateforme ReCut." />
      </Helmet>
      <div className="w-full">
        <div className="flex flex-col items-center gap-2.5">
          <div className="relative self-stretch ">
            <div className="flex w-full flex-col items-center">
              <div className="flex items-center self-stretch md:flex-col">
                <div className="relative w-[100%] md:h-auto md:w-full">
                  <div className="w-full">
                      <div className="flex flex-col items-center gap-[289px] md:gap-[216px] sm:gap-36">
                        <AdminGestionPageDistributeurNavbar />
                      </div>
                    </div>
                    <div class="container-xs mx-auto p-6 mb-6 bg-gray-900 text-default-white rounded-lg">
                        <h1 class="text-3xl font-bold mb-4 text-center">POLITIQUE DE CONFIDENTIALITÉ</h1>
                        <p class="text-sm mb-4 text-right">Entrée en vigueur le 9 juillet 2024</p>
                        <h2 class="text-2xl font-semibold mb-4">Bienvenue sur Re[ut !</h2>
                        <p class="mb-4">
                            Cette Politique de Confidentialité décrit comment nous collectons et traitons les données lorsque vous utilisez ou visitez le site internet
                            <Link to="/" class="text-blue-500 underline">www.recut.fr</Link> (site Web). Cette Politique fait partie de nos Conditions d’Utilisation et
                            y est incorporée par référence. Nous vous encourageons à la lire attentivement. En
                            utilisant nos Services, vous acceptez d'être légalement lié par les dispositions
                            suivantes :
                        </p>
                        <p class="mb-4">
                            Les termes « nous », « notre », la « Société », « Recut » et « Re[ut » font
                            référence à la Société ReCut, une société constituée conformément au droit
                            français, et immatriculée au registre du commerce et des sociétés de Paris n°929
                            960 375, dont le siège social est situé au 40 rue Alexandre Dumas, 75011 Paris.
                        </p>
                        <p class="mb-4">
                            Cette Politique de Confidentialité s'applique si vous résidez dans l'Espace
                            Économique Européen (EEE), des Conditions Supplémentaires Particulières
                            pourraient s’appliquer en fonction de votre lieu de résidence, de votre nationalité ou
                            citoyenneté. Dans ce cas, vous vous engagez à respecter les obligations légales de
                            la loi applicable à votre situation lors de l’utilisation de nos Services.
                        </p>
                        
                        <h2 class="text-2xl font-semibold mb-4">1. INFORMATIONS QUE NOUS COLLECTONS</h2>
                        <h3 class="text-xl font-semibold mb-2">(a) Les informations que vous nous fournissez volontairement</h3>
                        <p class="mb-4">
                            Nous pouvons vous demander certaines informations lorsque vous créez un compte
                            Recut ou que vous échangez avec nous (comme un nom d’utilisateur, vos nom et
                            prénom, votre date de naissance, votre numéro de téléphone, votre profession et
                            vos adresses physiques et e-mail).
                        </p>
                        <p class="mb-4">
                            Nous recueillons aussi les messages envoyés via notre Service (comme les
                            questions des clients, les requêtes de recherche), et collectons des informations
                            fournies dans le Compte utilisateur. Nous utilisons ces informations pour exploiter,
                            maintenir le Site Web et vous fournir les services et les fonctionnalités du Service,
                            pour correspondre avec vous et pour répondre à toute question que vous pourriez
                            vous poser au sujet du Service et pour nous conformer à nos obligations légales.
                        </p>
                        <p class="mb-4">
                            Si vous ne nous fournissez pas vos informations personnelles, il se peut que vous
                            ne puissiez pas accéder ou utiliser notre Service ou que votre expérience
                            d’utilisation de celui-ci ne soit pas aussi agréable.
                        </p>

                        <h3 class="text-xl font-semibold mb-2">Information d’inscription</h3>
                        <p class="mb-4">
                            Afin d'utiliser les Services fournis sur le site Web, vous devez ouvrir un compte «
                            Compte utilisateur » et effectuer votre inscription sur le site Web. Nous pouvons
                            vous demander à cette fin de fournir :
                        </p>
                        <ul class="list-disc list-inside mb-4">
                            <li>Votre nom complet ;</li>
                            <li>Votre adresse e-mail et de créer un mot de passe ;</li>
                            <li>Votre numéro de téléphone ;</li>
                            <li>Votre adresse complète (pays, état/province, code postal, ville, rue, numéro) ;</li>
                            <li>Votre sexe ;</li>
                            <li>Date et lieu de naissance ;</li>
                            <li>Numéro de carte d'identité ou de passeport (pays émetteur, numéro, date d'expiration, etc.) ;</li>
                            <li>Images et documents permettant votre identification ;</li>
                            <li>Preuve de l'adresse personnelle, comme mais sans s'y limiter (facture d'électricité, relevé bancaire, courrier gouvernemental), votre photo ou vidéo en selfie ;</li>
                            <li>de vous connecter via une application tierce dans laquelle vous avez un compte ouvert tel que Google, Gmail etc…</li>
                        </ul>
                        <p class="mb-4">
                            Nous utilisons et/ou stockons votre adresse e-mail : (a) Pour vérifier votre identité
                            lorsque vous vous connectez à votre compte ; (b) Pour vous contacter concernant
                            votre compte ou le fonctionnement du site Web (e-mails de support technique),
                            envoyer des e-mails avec nos newsletters, annonces et autres offres (e-mails
                            marketing) ; (c) Pour nous conformer à nos obligations légales.
                        </p>
                        <p class="mb-4">
                            Nous stockons les mots de passe protégés contre les accès non autorisés en
                            utilisant une fonction de hachage de mot de passe. Nous vous encourageons à créer
                            un mot de passe fort et à éviter de le partager avec d'autres pour la sécurité de votre
                            Compte utilisateur.
                        </p>
                        <h3 class="text-xl font-semibold mb-2">Contenu du Compte utilisateur</h3>
                        <p class="mb-4">
                            Nous collectons l’historique des activités effectuées sur le Compte utilisateur et
                            contenu que vous téléchargez, téléverser, envoyez, consultez, modifiez, éditez,
                            synchronisez, diffusez par le biais de nos Services et toute action que vous effectuez
                            sur notre site Web. Nous recueillons le contenu du Compte utilisateur par le biais
                            d’un préchargement au moment de la création, de l’importation ou du téléversement,
                            que vous choisissiez ou non d’enregistrer ou de téléverser ce contenu.
                        </p>
                        <p class="mb-4">
                            Lorsque vous effectuez un paiement sur le site Web, nous recueillons des
                            informations concernant la transaction d’achat ou de paiement, comme les
                            informations relatives à la carte de paiement, l’adresse de facturation, le montant, ou
                            toute donnée permettant de tracer la transaction.
                        </p>

                        <h3 class="text-xl font-semibold mb-2">(b) Les informations collectés automatiquement</h3>
                        <h4 class="text-lg font-semibold mb-2">Les informations techniques</h4>
                        <p class="mb-4">
                            Nous recueillons toutes informations techniques liées à l’utilisation de notre site
                            Web. Celles-ci peuvent comprendre l’adresse IP, l’identifiant unique de votre
                            appareil, le type de réseau, l’historique de vos connexions, le fabricant de l’appareil,
                            les mises à jour, la langue et les paramètres régionaux du système et toute
                            information relative au réseau de données liés à l’utilisation de votre appareil. Nous
                            recueillons votre localisation approximative dès votre inscription sur le site Web et
                            ce, par le biais des données émises par votre adresse IP.
                        </p>
                        <p class="mb-4">
                            Nous recueillons des informations sur votre interaction avec le contenu disponible
                            sur le site Web que vous consultez, la durée, les téléchargements et la fréquence
                            d’utilisation. Ces informations peuvent comprendre les préférences de contenu, les
                            publicités visionnées, les contenus utilisés.
                        </p>
                        <h4 class="text-lg font-semibold mb-2">Cookies</h4>
                        <p class="mb-4">
                            Lorsque vous utilisez notre Service, nous (et nos partenaires tiers) enverrons des
                            cookies, soit de petits fichiers texte contenant une chaîne de caractères
                            alphanumériques, sur votre ordinateur qui identifient votre navigateur de manière
                            unique et nous permettent de vous aider à vous connecter plus rapidement, à
                            améliorer votre navigation sur le site Web, à mémoriser vos préférences et, de
                            manière générale, à améliorer l’expérience utilisateur. Les cookies peuvent
                            également nous transmettre des informations sur votre manière d’utiliser le Service
                            (comme par exemple, les pages que vous consultez, les liens sur lesquels vous
                            cliquez et vos autres actions sur le Service) et nous permettre, à nous ou à nos
                            partenaires commerciaux, de suivre votre utilisation du Service au fil du temps. Ils
                            nous permettent également de mesurer le trafic et les tendances d’utilisation du
                            Service, de diffuser des publicités personnalisées susceptibles de vous intéresser et
                            de mesurer leur efficacité, et de trouver de nouveaux utilisateurs potentiels du
                            Service.
                        </p>
                        <p class="mb-4">
                            Vous pouvez contrôler ou réinitialiser vos cookies dans votre navigateur, qui vous
                            permettra de personnaliser vos préférences de cookie et de refuser tous les cookies
                            ou bien de savoir lorsqu’un cookie est envoyé. Cependant, certaines fonctionnalités
                            du Service peuvent ne pas fonctionner correctement si la possibilité d’accepter des
                            cookies est désactivée. Pour plus d’informations sur la façon dont nous utilisons les
                            cookies et d’autres technologies et sur la façon dont vous pouvez les contrôler,
                            veuillez lire notre Politique relative aux cookies.
                        </p>
                        <h4 class="text-lg font-semibold mb-2">Les prestataires de paiement</h4>
                        <p class="mb-4">
                            Nous recevons des informations vous concernant de la part de nos partenaires ainsi
                            que des prestataires de paiement et d’exécution de transactions. Il s’agit par
                            exemple des détails de confirmation de paiement et d’informations au sujet d’achat
                            de la licence ou de contenu.
                        </p>
                        <h4 class="text-lg font-semibold mb-2">Informations provenant de tiers</h4>
                        <p class="mb-4">
                            Vous pouvez choisir de créer un compte via un tiers, tel que Google par exemple. Si
                            tel est le cas, nous pouvons recueillir des informations tels que votre profil public,
                            votre nom d’utilisateur, votre adresse mail, votre photo de profil ou des informations
                            sur votre utilisation de ces services. Nous recevons également les informations
                            nécessaires pour vous fournir des fonctionnalités telles que l’authentification
                            interservices ou la publication croisée. Par exemple, cela se produira si vous utilisez
                            le bouton « télécharger le clip» de Recut vers une site Web ou application de tiers
                            pour partager le contenu.
                        </p>
                        
                        <h2 class="text-2xl font-semibold mb-4">2. UTILISATION DE VOS INFORMATIONS</h2>
                        <p class="mb-4">
                            Vos données sont recueillis afin de :
                        </p>
                        <ul class="list-disc list-inside mb-4">
                            <li>Vérifier votre âge et votre identité.</li>
                            <li>Gérer et vous fournir nos Services, en vous permettant d’éditer, modifier, synchroniser, partager du contenu utilisateur et de participer à des fonctionnalités interactives, y compris pour promouvoir votre contenu auprès d'autres utilisateurs si vous le souhaitez.</li>
                            <li>Communiquer avec vous, notamment pour vous informer des changements apportés aux Services et au site Web.</li>
                            <li>Vous fournir des informations ou des contenus personnalisés et susceptibles de vous intéresser, comme des communications marketing adaptées à vos préférences.</li>
                            <li>Appliquer nos Conditions d’Utilisation.</li>
                            <li>Répondre à vos questions et demandes afin de les traiter efficacement.</li>
                            <li>Améliorer et développer nos Services et fonctionnalités, en veillant à ce qu’elles soient présentées de manière optimale pour vous et votre appareil, et en perfectionnant nos technologies.</li>
                            <li>Administrer le site Web et effectuer des opérations internes, telles que le dépannage, l'analyse des données, les tests, la recherche, les statistiques et les enquêtes.</li>
                            <li>Détecter les abus, la fraude et les activités illégales liées sur le site Web, et assurer votre sécurité en scannant, analysant et examinant le contenu du Compte utilisateur et les métadonnées associées pour identifier les violations de nos Conditions d’utilisation et autres conditions et politiques actuelles ou ultérieures.</li>
                            <li>Fournir des services publicitaires, y compris l'envoi de publicités (notamment personnalisées, lorsque cela est autorisé) et évaluer l'efficacité des campagnes publicitaires.</li>
                            <li>Satisfaire à nos obligations légales ou, lorsque nécessaire, protéger les intérêts vitaux de nos utilisateurs et d'autres personnes. Nous pouvons également utiliser vos informations à d’autres fins qui vous sont divulguées au moment où nous les recueillons, avec votre consentement, ou comme autrement autorisé par la loi applicable.</li>
                        </ul>
                        <p class="mb-4">
                            Nonobstant le droit applicable dans votre juridiction, nous ne tolérerons pas que les
                            Services et fonctionnalités du site Web soient utilisés afin de commettre des activités
                            illicites sur notre site Web ou ailleurs. Si nous notons dans le cadre de la collecte de
                            données que des activités illicites sont commises, nous prendrons l'initiative de
                            bloquer l’accès à votre Compte utilisateur et de remettre les éléments de preuve aux
                            autorités compétentes. Est considéré comme illicite tout contenu, créer à partir des
                            éléments founis par nos Services, et qui est extrêmement violent ou choquant, ainsi
                            que les contenus encourageant le suicide, l'automutilation ou les comportements
                            dangereux, les discours haineux et la discrimination sous toutes leurs formes, le
                            harcèlement et l'intimidation, le contenu diffusé à partir du site Web ou autre et qui
                            est sexuellement explicite ou pornographique.
                        </p>

                        <h2 class="text-2xl font-semibold mb-4">3. LE PARTAGE DE VOS INFORMATIONS</h2>
                        <h3 class="text-xl font-semibold mb-2">Avec nos prestataires de services et partenaires commerciaux</h3>
                        <p class="mb-4">
                            Nous pouvons partager toutes les informations inscrites au “1. Les informations que
                            nous collectons” de cette Politique avec nos prestataires de services et nos
                            partenaires commerciaux.
                        </p>
                        <p class="mb-4">
                            Ils fournissent des services tels que l’hébergement cloud, le marketing et le
                            paiement en ligne. Ils fournissent également les images, sons, vidéos auxquels vous
                            avez accès sur le Site Web. Ils fournissent une assistance technique et informatique
                            afin d’assurer le bon fonctionnement du site Web.
                        </p>
                        <h3 class="text-xl font-semibold mb-2">Fournisseurs de services de réseaux sociaux tiers</h3>
                        <p class="mb-4">
                            Si vous choisissez de vous inscrire ou de vous connecter à notre site Web en
                            utilisant votre compte Google ou d'autres services tiers, ces services tiers pourront
                            collecter des informations vous concernant, y compris sur votre activité au sein du
                            site Web. Nous pourrons également être informés de vos connexions sur ces
                            services tiers, selon leurs politiques de confidentialité.
                        </p>
                        <p class="mb-4">
                            En autorisant un service tiers à accéder à votre compte, nous partageons certaines
                            informations vous concernant avec ce service, telles que votre identifiant
                            d'application et l'URL de référence. Selon les autorisations que vous accordez, le
                            service tiers pourra accéder aux informations de votre compte et aux autres données
                            que vous choisissez de fournir.
                        </p>
                        <h3 class="text-xl font-semibold mb-2">Partenaires de publicité et de mesure</h3>
                        <p class="mb-4">
                            Dans le but de vous présenter des publicités, nous pouvons partager les
                            informations collectées lors de votre utilisation de notre site Web (telles que les
                            identifiants mobiles et l’adresse IP de votre appareil, ainsi que des informations
                            connexes sur votre localisation présumée et l’utilisation de la site Web) avec nos
                            partenaires publicitaires. Ces partenaires nous aident à diffuser des publicités
                            pertinentes à travers notre site Web.
                        </p>
                        <p class="mb-4">
                            En fonction de la juridiction à partir de laquelle vous accédez à notre site Web, nous
                            sommes susceptibles de vous présenter des publicités basées sur votre activité sur
                            d’autres sites, applications et services. Ces informations sont généralement
                            collectées via des cookies et des technologies de suivi similaires afin de vous fournir
                            des publicités plus pertinentes. Vous pouvez vous désinscrire grâce aux contrôles de
                            publicité qui se trouvent dans vos paramètres.
                        </p>
                        <p class="mb-4">
                            Nous pouvons partager les informations collectées lors de l'utilisation de nos
                            services et les informations sur les performances de ces publicités (telles que les
                            vues, l'engagement et les clics) avec des partenaires de mesure et de publicité afin
                            de mesurer l'efficacité de leurs campagnes publicitaires. Nous pouvons également
                            partager des rapports agrégés sur la performance des campagnes publicitaires avec
                            les annonceurs.
                        </p>
                        <h3 class="text-xl font-semibold mb-2">Les intérêts légitimes</h3>
                        <p class="mb-4">
                            Nous utilisons vos informations lorsque cela est nécessaire pour satisfaire des
                            intérêts légitimes, qu’ils nous appartiennent, qu’ils vous appartiennent ou qu’ils
                            appartiennent à un tiers, à condition que vos intérêts ou vos droits et libertés
                            fondamentaux ne prévalent pas sur ces intérêts.
                        </p>
                        <p class="mb-4">
                            Nous utilisons les informations que vous fournissez, collectés automatiquement et
                            provenant d’autres sources pour :
                        </p>
                        <ul class="list-disc list-inside mb-4">
                            <li>Pour nous permettre d'administrer au mieux le site Web, pour que vous puissiez éditer, synchroniser, modifier ou télécharger au mieux notre contenu. Nous utilisons également ces informations pour promouvoir du contenu ou pour vous fournir du contenu adapté à vos envies.</li>
                            <li>Pour comprendre comment vous utilisez les services, les améliorer et les développer.</li>
                            <li>Pour vous fournir des publicités personnalisées selon votre activité sur notre site Web mais également sur des réseaux partenaires. Nous ou nos partenaires publicitaires tiers demandons votre consentement que vous pouvez retirer à tout moment en accédant aux paramètres de votre compte.</li>
                            <li>Pour vous fournir des publicités non personnalisées. Nous ou nos partenaires publicitaires tiers proposons des publicités non personnalisées afin de maintenir la gratuité de certains de nos services.</li>
                            <li>Pour mesurer et comprendre l’efficacité des publicités qui vont sont proposées. Le traitement est nécessaire pour nous permettre ainsi qu'à nos partenaires, de connaître l’impact que nos publicités ont eu sur les consommateurs, afin de les adapter et d’améliorer l’expérience de nos utilisateurs.</li>
                            <li>Pour aider à l’application de la loi et aux enquêtes menées par toute autorité publique ou réglementaire. Le traitement est nécessaire pour nous conformer à nos obligations légales en vertu du droit européen (chapitres IV et VI du règlement général sur la protection des données). De plus, dans certains cas, il peut être nécessaire de partager vos informations avec certaines entités gouvernementales.</li>
                            <li>Pour communiquer avec vous, répondre à vos demandes et réclamations ou vous informer des modifications apportées à nos services ou politiques. Le traitement est nécessaire à l’exécution d’un contrat conclu avec vous.</li>
                            <li>Pour vérifier que vous avez l’âge légal minimum requis pour utiliser nos services. Le traitement est nécessaire à l’exécution d’un contrat conclu avec vous et pour satisfaire aux conditions légales.</li>
                            <li>Pour détecter les abus, la fraude, les activités illégales, pour assurer votre sûreté et votre sécurité, analyser et examiner le contenu utilisateur et les métadonnées associées pour repérer les violations de nos conditions et politiques. Le traitement est nécessaire pour garantir la sûreté et la sécurité de nos services ainsi que se conformer à nos obligations légales en vertu du droit européen.</li>
                            <li>Pour notre recherche, nos statistiques afin d’améliorer les performances de la site Web, dans les mesures autorisées par la loi européenne. Le traitement est nécessaire pour mener des recherches. Pour cela, nous pouvons utiliser l’anonymisation.</li>
                            <li>Pour protéger votre vie, votre intégrité, votre sécurité. Le traitement est nécessaire pour protéger vos intérêts vitaux ou ceux d’autres personnes.</li>
                            <li>Pour toute autre mesure légitimement nécessaire aux besoins du site Web ou pour satisfaire aux conditions légales.</li>
                        </ul>

                        <h2 class="text-2xl font-semibold mb-4">4. OBLIGATIONS LÉGALES</h2>
                        <p class="mb-4">
                            Nous pouvons partager les informations que nous collectons avec des organismes
                            chargés de l’application de la loi, des autorités publiques ou d’autres entités si la loi
                            l’exige, ou si nous pensons en toute bonne foi qu’une telle utilisation est
                            raisonnablement nécessaire pour:
                        </p>
                        <ul class="list-disc list-inside mb-4">
                            <li>nous conformer à l’ensemble des lois, réglementations, codes de pratique, directives gouvernementales ou autres obligations légales et réglementaires applicables, conformément aux normes internationalement reconnues ;</li>
                            <li>appliquer nos Conditions d’Utilisation et y compris l’enquête sur toute violation potentielle de ceux-ci ;</li>
                            <li>exercer ou défendre des droits, y compris pour répondre à des assignations, ordonnances judiciaires, procédures judiciaires, demandes d’application de la loi, droits légaux ou demandes gouvernementales ;</li>
                            <li>détecter, prévenir ou autrement résoudre les problèmes de sécurité, de fraude ou techniques ;</li>
                            <li>protéger les droits, la propriété ou la sécurité en ce qui nous concerne, ou en ce qui concerne nos utilisateurs, un tiers ou le public tel que requis ou autorisé par la loi (y compris l’échange d’informations avec d’autres sociétés et organisations à des fins de protection contre la fraude, lutte contre le blanchiment d’argent et le financement du terrorisme).</li>
                        </ul>

                        <h2 class="text-2xl font-semibold mb-4">5. VENTE ET FUSION</h2>
                        <p class="mb-4">
                            En cas de vente, de fusion, d’achat d’actifs, portant même sur une petite partie de
                            l’entreprise, à un tiers, vos informations d’utilisateur peuvent être fournies à
                            l’acheteur potentiel au titre d’actifs.
                        </p>
                        <a href="https://www.capcut.com/terms-of-service?from_page=landing_page&enter_from=page_footer" target="_blank" rel="noreferrer" class="text-blue-500 underline mb-4 block">
                            https://www.capcut.com/terms-of-service?from_page=landing_page&enter_from=page_footer
                        </a>

                        <h2 class="text-2xl font-semibold mb-4">6. TRANSFERTS INTERNATIONAUX DE DONNÉES</h2>
                        <p class="mb-4">
                            Les informations personnelles que nous recueillons auprès de vous peuvent être
                            stockées sur un serveur situé en dehors du pays où vous résidez. Nous stockons les
                            informations que nous collectons sur des serveurs sécurisés situés dans l’Union
                            Européenne Lorsque nous transférons des informations personnelles hors du pays
                            où vous résidez, y compris pour une ou plusieurs des finalités énoncées dans la
                            présente Politique, nous le faisons conformément aux exigences des lois applicables
                            en matière de protection des données.
                        </p>

                        <h2 class="text-2xl font-semibold mb-4">7. VOS DROITS ET CHOIX</h2>
                        <p class="mb-4">
                            Conformément à la loi Informatique et Liberté n°78-17 du 6 janvier 1978 modifiée et
                            au Règlement Général pour la Protection des Données (RGPD) N°2016/679 du 27
                            avril 2016, les Utilisateurs disposent d’un droit d’accès, de rectification et
                            d’opposition des informations nominatives les concernant, qu’ils peuvent exercer sur
                            simple demande à : <a href="mailto:dpo@recut.fr" class="text-blue-500 underline">dpo@recut.fr</a>
                        </p>
                        <p class="mb-4">
                            Vous pouvez également vous connecter et modifier à tout moment vos informations
                            via votre Compte utilisateur ou gérer les paramètres intégrer au site Web.
                        </p>
                        <p class="mb-4">
                            Si vous choisissez de vous connecter, de vous inscrire à un service tiers, ou d’établir
                            un lien vers un service tiers, tel que Google, TikTok, Instagram ou YouTube, vous
                            pourrez gérer le type de données partagées avec le site Web via les paramètres de
                            ce service.
                        </p>
                        <p class="mb-4">
                            Vous pouvez contrôler la collecte de vos données publicitaires via les paramètres qui
                            figurent sur votre appareil.
                        </p>
                        <p class="mb-4">
                            Vous pouvez vous désinscrire des e-mails de marketing ou publicitaires en utilisant
                            le lien ou le mécanisme de « désinscription » indiqué dans les e-mails de marketing
                            ou publicitaires.
                        </p>
                        <p class="mb-4">
                            Vous pouvez également bénéficier de droits dans votre pays en vertu des lois
                            applicables, comme la possibilité d’accéder à vos données, de supprimer celles-ci,
                            d’être informé de l’utilisation de vos données, de révoquer votre consentement, ou
                            de déposer une réclamation auprès de l’autorité compétente.
                        </p>
                        <p class="mb-4">
                            Lorsque vous résidez dans l'Espace Economique Européen vous disposez des
                            droits suivants:
                        </p>
                        <ul class="list-disc list-inside mb-4">
                            <li>Le droit de demander gratuitement (i) la confirmation que nous traitons vos
                                données personnelles et (ii) l’accès à une copie des données personnelles
                                que nous conservons.</li>
                            <li>Le droit de demander la rectification ou la suppression appropriée de vos
                                données personnelles, ou la limitation de leur traitement.</li>
                            <li>Si le traitement de vos données personnelles est basé sur votre
                                consentement ou est nécessaire à l’exécution d’un contrat avec vous, et que
                                ce traitement est effectué par des moyens automatisés, le droit de recevoir
                                vos données personnelles dans un format structuré, couramment utilisé et
                                lisible par machine, ou de les faire transmettre directement à une autre
                                entreprise, lorsque cela est techniquement possible (portabilité des données).</li>
                            <li>Lorsque le traitement de vos données personnelles est basé sur votre
                                consentement, le droit de retirer ce consentement à tout moment (le retrait
                                n’affectera pas la licéité des traitements effectués avant le retrait).</li>
                            <li>Le droit de ne pas faire l’objet de décisions individuelles automatisées, y
                                compris le profilage, ayant des effets juridiques sur vous ou vous affectant de
                                manière significative, sauf si nous avons obtenu votre consentement, si cela
                                est autorisé par le droit de l’Union ou d’un État membre, ou si cela est
                                nécessaire à l’exécution d’un contrat.</li>
                            <li>Le droit de vous opposer au traitement de vos données personnelles si nous
                                les traitons sur la base de notre intérêt légitime, sauf si nous pouvons
                                démontrer des motifs légitimes impérieux qui prévalent sur votre droit. Si vous
                                vous opposez à un tel traitement, nous vous demandons d’indiquer les motifs
                                de votre opposition afin que nous puissions examiner le traitement de vos
                                données personnelles et mettre en balance notre intérêt légitime et votre
                                opposition.</li>
                            <li>Le droit de demander la limitation du traitement de vos informations dans les
                                cas suivants : a) vous contestez l’exactitude des informations ; b) les
                                informations ont été traitées de manière illégale, mais vous vous opposez à
                                leur suppression ; c) vous avez besoin que les informations soient conservées
                                pour faire valoir ou défendre des droits légaux ; ou d) vous vous êtes opposé
                                au traitement et attendez le résultat de cette démarche.</li>
                            <li>Le droit de vous opposer au traitement de vos données personnelles à des
                                fins de marketing direct.</li>
                            <li>Le droit de déposer une réclamation auprès de votre autorité locale de
                                protection des données.</li>
                        </ul>

                        <h2 class="text-2xl font-semibold mb-4">8. SÉCURITÉ DES DONNÉES</h2>
                        <p class="mb-4">
                            Nous appliquons des mesures de sécurité techniques, administratives et physiques
                            appropriées visant à protéger vos informations contre l’accès non autorisé, le vol, la
                            divulgation, la modification ou la perte. Nous maintenons ces mesures techniques et
                            organisationnelles et nous les modifierons de temps à autre afin d’améliorer la
                            sécurité globale de nos systèmes.
                        </p>
                        <p class="mb-4">
                            Cependant, il existe toujours un risque de vol des données, nous ne pouvons donc
                            pas garantir la sécurité de vos informations transmises via les Services.
                        </p>

                        <h2 class="text-2xl font-semibold mb-4">9. DURÉE DE CONSERVATION DES DONNÉES</h2>
                        <p class="mb-4">
                            Nous conservons vos informations aussi longtemps qu’il est nécessaire de vous
                            fournir le service et aux autres fins énoncées dans la présente Politique de
                            confidentialité ou tel qu’exigé par la loi.
                        </p>
                        <p class="mb-4">
                            Les périodes de conservation varieront en fonction du type d’informations et des
                            finalités pour lesquelles nous utilisons les informations.
                        </p>
                        <p class="mb-4">
                            Si vous supprimez ou désactivez votre Compte utilisateur, nous pourrons conserver
                            vos informations de profil et le contenu pendant une durée commercialement
                            raisonnable, et aussi longtemps que nous avons un but valable de le faire. Nous
                            pourrons en particulier conserver vos informations dans le but de respecter ses
                            obligations légales et d’audit, ainsi que pour des questions de sauvegarde et
                            d’archivage.
                        </p>
                        <p class="mb-4">
                            Nous ne consulterons, ne traiterons ni ne supprimerons aucun contenu ou aucune
                            information que vous avez activement téléchargé(e) sans votre consentement, sauf
                            si la loi ou la réglementation l’exige, et hormis le cas où le paramètre NFT du Service
                            est activé.
                        </p>

                        <h2 class="text-2xl font-semibold mb-4">10. INFORMATIONS RELATIVES AUX MINEURS</h2>
                        <p class="mb-4">
                            Le site Web n’est pas destiné aux enfants âgés de moins de 13 ans. L’âge minimum
                            requis pour utiliser notre site Web peut varier en fonction du pays ou de la région
                            dans lequel/laquelle vous vous trouvez.
                        </p>

                        <h2 class="text-2xl font-semibold mb-4">11. MISE À JOUR DE LA POLITIQUE DE CONFIDENTIALITÉ</h2>
                        <p class="mb-4">
                            Nous pouvons modifier ou mettre à jour la présente Politique de confidentialité.
                            Lorsque nous mettrons à jour la Politique de confidentialité, nous vous informerons
                            de toute modification apportée à cette politique en mettant à jour la date de «
                            Dernière mise à jour » située en haut de la nouvelle Politique de confidentialité, en
                            publiant la nouvelle Politique de confidentialité, ou en fournissant un autre avis
                            requis par la loi applicable. Nous vous recommandons de consulter régulièrement la
                            présente Politique pour rester informé de nos pratiques en matière de confidentialité.
                            La poursuite de l’utilisation de nos Services après une mise en jour vaut
                            consentement plein, entier et irrévocable des termes de la mise à jour.
                        </p>

                        <h2 class="text-2xl font-semibold mb-4">12. NOUS CONTACTER</h2>
                        <p class="mb-4">
                            Les questions, commentaires et demandes concernant cette Politique sont les
                            bienvenus et doivent être adressés à : <a href="mailto:dpo@recut.fr" class="text-blue-500 underline">dpo@recut.fr</a>
                        </p>
                        <p class="mb-4">
                            Notre représentant RGPD désigné est Monsieur Noël ESNAULT, Société Recut 40
                            rue Alexandre Dumas, 75011 Paris.
                        </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
    </>
  );
}
