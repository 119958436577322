import { Helmet } from "react-helmet";
import { Button, Heading } from "../../components";
import Header from "../../components/Header";
import TLChargementStackscneomonda from "../../components/TLChargementStackscneomonda";
import React, { Suspense } from "react";
import AdminGestionPageDistributeurNavbar from "components/AdminGestionPageDistributeurNavbar";

const data = [
  {},
  {
    scenetext: "Scène où Mondain chante ultra mal",
    movietext: "Les Choristes (2004) - Pathé",
    pricetext: "0,5 €",
    timetext: "02:30",
  },
  {
    scenetext: "Scène où Mondain chante ultra mal",
    movietext: "Les Choristes (2004) - Pathé",
    pricetext: "0,5 €",
    timetext: "02:30",
  },
  { timetext: "02:30" },
];

export default function TlchargementPage() {
  return (
    <>
      <Helmet>
        <title>ReCut</title>
        <meta name="description" content="Visionnage, téléchargement et utilisation des contenus mis à disposition sur la plateforme ReCut." />
      </Helmet>
      <div className="relative w-full">
        <div className="bg-cover bg-no-repeat pb-[134px] md:h-auto md:pb-5">
          <div className="">
            <AdminGestionPageDistributeurNavbar />
            <div className="container-xs flex flex-col items-center px-[39px] md:p-5 sm:px-5">
              <Heading size="headline_2" as="h1">
                Panier
              </Heading>
              <div className="mt-[78px] flex flex-col self-stretch">
              <Suspense fallback={<div className="loader">Loading feed...</div>}>
                  {data.map((d, index) => (
                    <TLChargementStackscneomonda {...d} key={"tlchargement" + index} />
                  ))}
                </Suspense>
              </div>
              <Button
                color="deep_purple_A200"
                size="3xl"
                shape="round"
                className="min-w-[238px] self-end font-inter font-medium sm:px-5"
              >
                Payer 25,62 €
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
