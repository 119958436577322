import { Img } from 'components';
import MenuMenuclient from 'components/MenuMenuclient';
import React, { useState, useRef, useEffect } from 'react';
import 'video-react/dist/video-react.css';

const MenuClient = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const toggleMenu = () => setIsMenuOpen(prev => !prev);

  const handleClickOutside = event => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={menuRef}>
      <a href="#" onClick={toggleMenu} className="md:w-[50px] relative h-[50px] flex justify-center items-center">
        <Img
          src="/images/imagehamburg.png"
          alt="image"
          className="h-[30px] w-[30px]  object-cover"
        />
      </a>
      {isMenuOpen && (
        <div className="absolute menumenuclient top-[80px] right-[0px] z-[99999] flex max-w-max flex-col items-start rounded-lg bg-cover bg-no-repeat p-[35px] md:mr-0 md:h-auto md:w-full sm:p-5">
          <MenuMenuclient />
        </div>
      )}
    </div>
  );
};

export default MenuClient;
