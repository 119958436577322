import React from "react";
import { useRoutes } from "react-router-dom";
// import Home from "pages/Home";
// import Landing from "pages/Landing";
// import Home1 from "pages/Home1";
// import AdminGestionpagedistributeur from "pages/AdminGestionpagedistributeur";
// import Browsecategorie from "pages/Browsecategorie";
// import Tlchargement from "pages/Tlchargement";
// import Paiement from "pages/Paiement";
// import Tlchargement1 from "pages/Tlchargement1";
// import Crationcompteviarseaux1 from "pages/Crationcompteviarseaux1";
// import Browsecategorie1 from "pages/Browsecategorie1";
// import Crationcompteviamail from "pages/Crationcompteviamail";
// import Menu from "pages/Menu";
// import Messquences from "pages/Messquences";
// import Editermasquence from "pages/Editermasquence";
// import Wallet from "pages/Wallet";
// import Prfrencesdecontenu from "pages/Prfrencesdecontenu";
// import AdminCatalogue from "pages/AdminCatalogue";
// import AdminCatalogue1 from "pages/AdminCatalogue1";
// import AdminCatalogue3 from "pages/AdminCatalogue3";
// import AdminCatalogue5 from "pages/AdminCatalogue5";
// import AdminCatalogue6 from "pages/AdminCatalogue6";
// import AdminUpload from "pages/AdminUpload";
// import Tlchargement1Page from "pages/Tlchargement1";
import NotFound from "pages/NotFound";
import Crationcompteviarseaux from "pages/Crationcompteviarseaux";
import Connexion from "pages/Connexion";
import Browse from "pages/Browse";
import Film from "pages/Film";
import Editermonextrait from "pages/Editermonextrait";
import Mesinfospersonnelles from "pages/Mesinfospersonnelles";
import PrivateRoute from "./PrivateRoute";
import Register from "pages/Register";
import MesssequencessixPage from "pages/Messsequencessix";
import MescommandesonePage from "pages/Mescommandesone";
import WalletPage from "pages/Wallet";
import PrfrencesdePage from "pages/Prfrencesde";
import MentionslPage from "pages/Mentionsl";
import TlchargementPage from "pages/Tlchargement";
import PrivacyPolicy from "pages/PrivacyPolicy";
import ConditionsGeneralesUtilisation from "pages/ConditionsGeneralesUtilisation";
import ConditionsGeneralesUtilisationRecut from "pages/ConditionsGeneralesUtilisationRecut";

const ProjectRoutes = () => {
  const element = useRoutes([
    { path: "/", element: <Browse /> },
    { path:"/show/:id", element: <PrivateRoute><Editermonextrait /></PrivateRoute>},
    { path:"/film/:id", element: <Film />},
    { path:"/messsequencessix", element: <PrivateRoute><MesssequencessixPage /></PrivateRoute>},
    { path: "/mesinfospersonnelles", element: <PrivateRoute><Mesinfospersonnelles /></PrivateRoute> },
    { path: "/mescommandesone", element: <PrivateRoute><MescommandesonePage /></PrivateRoute> },
    { path: "/wallet", element: <PrivateRoute><WalletPage /></PrivateRoute> },
    { path: "/prfrencesde", element: <PrivateRoute><PrfrencesdePage /></PrivateRoute> },
    { path: "/mentionsl", element: <PrivateRoute><MentionslPage /></PrivateRoute> },
    { path: "/panier", element: <PrivateRoute><TlchargementPage /></PrivateRoute> },
    { path: "/conditions-generales-utilisation", element: <ConditionsGeneralesUtilisation /> },
    { path: "/conditions-generales-utilisation-recut", element: <ConditionsGeneralesUtilisationRecut /> },
    { path: "/connexion", element: <Connexion /> },
    { path: "/connexionseaux", element: <Crationcompteviarseaux /> },
    { path: "/register", element: <Register /> },
    { path: "/privacy-policy", element: <PrivacyPolicy /> },
    { path: "/404", element: <NotFound /> },
    { path: "/:pseudofilm", element: <Film /> },
    
    { path: "*", element: <NotFound /> },
  ]);

  return element;
};

export default ProjectRoutes;
