import { Helmet } from "react-helmet";
import { SelectBox, Img, Text, Input } from "../../components";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import React from "react";
import AdminGestionPageDistributeurNavbar from "components/AdminGestionPageDistributeurNavbar";


export default function PrfrencesdePage() {
  return (
    <>
      <Helmet>
        <title>ReCut</title>
        <meta name="description" content="Visionnage, téléchargement et utilisation des contenus mis à disposition sur la plateforme ReCut." />
      </Helmet>
      <div className="relative w-full">
        <div className="flex flex-col z-[5] gap-[27px]">
          <AdminGestionPageDistributeurNavbar />
          <div className="ml-[54px] flex w-[66%] items-start gap-2.5 md:ml-0 md:w-full md:p-5">
            <Sidebar />
            
            <div className="flex flex-1 flex-col items-center justify-center rounded-lg bg-gray-900 p-[50px] md:p-5">
              
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
