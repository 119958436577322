import { Img, Text } from "./..";
import React from "react";
import { Link } from "react-router-dom";

export default function Footer({ producteur="",...props }) {
  return (
    <footer {...props} className={`${props.className} flex justify-center  items-end py-[15px] mt-[20px] text-white`} style={{"background":"linear-gradient(transparent, black)"}}>
      <div className="container-xs mt-[17px] flex justify-center md:p-5">
        <div className="flex w-full items-center justify-between gap-5 sm:flex-col">
            <Link to="/">
                <Img
                    alt="e5"
                    className="w-[70px]"
                    src={"/images/logo-recut-white.png"}
                />
            </Link>
            {producteur=="pathe" && 
            <Link to="/conditions-generales-utilisation" className="text-white">
                <Text as="p" className="max-w-max">Conditions Générales d'utilisation</Text>
            </Link>
            }
            {producteur=="recut" && 
            <Link to={"/conditions-generales-utilisation-"+producteur} className="text-white">
                <Text as="p" className="max-w-max">Conditions Générales d'utilisation</Text>
            </Link>
            }
            
            <Link to="/privacy-policy" className="text-white">
                <Text as="p" className="max-w-max">Politique de Confidentialité</Text> 
            </Link>
            <a href="https://www.tiktok.com/@recut_app?_t=8njreN74mUi&_r=1" target="_blank" rel="noreferrer"  className="text-white">
                <Text as="p" className="max-w-max">Tiktok</Text>
            </a>
        </div>
      </div>
    </footer>
  );
}
