import axios from 'axios';
import { Navigate } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL+"/auth";
const BASE_URL = 'https://graph.facebook.com/v14.0';
export async function login(email,password) {
  try {
    const response = await axios.post(apiUrl+'/login', { email, password });
    if(response.data.token && response.data.token!=""){
       localStorage.setItem('token', response.data.token);
        return true; 
    }
    
  } catch (error) {
    console.log('Error fetchData:', error);
    throw error;
  }
  return false;
}


export async function register(email,password) {
    try {
        const response = await axios.post(apiUrl+'/register', { email, password });
        if(response.data.token && response.data.token!=""){
            localStorage.setItem('token', response.data.token);
             return true; 
         }
    } catch (error) {
      console.log('Error fetchData:', error);
      throw error;
    }
}

export function InstagramLogin() { 
  window.location = apiUrl+'/instagram';
}

export function TikTokLogin() {
  window.location = apiUrl+'/tiktok';
}

export function GoogleLogin() {
  window.location = apiUrl+'/google';
}

export const logoutUser = () => {
  localStorage.removeItem('token'); // Ou tout autre stockage utilisé pour le jeton
  window.location = "/connexionseaux";
  return <Navigate to="/connexionseaux" /> // Redirigez vers la page de connexion
};

export const isLogin = () => {
  if (localStorage.getItem('token')) {
    return true;
  }else{
    return false; 
  }
};

export const isLoginAvecRedirection = () => {
  if (localStorage.getItem('token')) {
    return true;
  }else{
    window.location = "/connexionseaux";
  }
};

export const createMediaContainer  = async (accessToken, userId, videoUrl, caption) => {
  const response = await axios.post(
    `${BASE_URL}/${userId}/media`,
    {
      media_type: 'VIDEO',
      video_url: videoUrl,
      caption: caption,
      access_token: accessToken,
    }
  );
  return response.data.id;
};

export const publishMediaContainer   = async (accessToken, userId, containerId) => {
  const response = await axios.post(
    `${BASE_URL}/${userId}/media_publish`,
    {
      creation_id: containerId,
      access_token: accessToken,
    }
  );
  return response.data;
};
