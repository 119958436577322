import { Text, Img, Button, Heading } from "./..";
import React from "react";

export default function TLChargementStackscneomonda({
  scenetext = "Scène où Mondain chante ultra mal",
  movietext = "Les Choristes (2004) - Pathé",
  pricetext = "0,5 €",
  timetext = "02:30",
  ...props
}) {
  return (
    <div {...props} className={`${props.className} h-[auto] md:w-full flex relative md:flex-wrap mb-[10px] bg-gray-900 rounded-[15px]`}>
      <div className="relative m-auto m-[10px] h-[190px] w-[35%] md:w-[100%] pb-[52px] md:h-auto md:pb-5">
        <Img
          src="images/img_rectangle_92_472x291.png"
          alt="image"
          className="h-[190px] w-full rounded-[7px] object-cover"
        />
        <div className="absolute right-[9.00px] top-[9.00px] m-auto flex w-[42%]  justify-end gap-[5px]">
          <div className="flex w-full gap-1 rounded bg-black-900_bf">
            <Img src="images/img_star_10_68.svg" alt="image" className="h-[14px] w-[14px]" />
            <Img src="images/img_star_10_69.svg" alt="image" className="h-[14px] w-[14px]" />
            <Img src="images/img_star_11_53.svg" alt="image" className="h-[14px] w-[14px]" />
          </div>
          <Text as="p" className="flex items-center justify-center md:w-[100%] rounded bg-black-900_bf px-[5px] py-1">
            {timetext}
          </Text>
        </div>
      </div>
      <div className="m-auto flex w-full items-start justify-between gap-[250px] pl-[30px]  sm:relative sm:flex-col sm:gap-5 sm:p-5">
        <div className="mb-10 flex flex-col items-start gap-5 sm:mb-0">
          <Heading size="headline_3" as="h3">
            {scenetext}
          </Heading>
          <Heading size="subtitle_1" as="h6">
            {movietext}
          </Heading>
          <Heading size="subtitle_1" as="h6">
            {pricetext}
          </Heading>
        </div>
        <Button
          color="gray_900"
          size="4xl"
          shape="round"
          className="mb-[38px] mr-10 w-[52px] border border-solid border-deep_purple-a200_01 sm:mb-0 sm:mr-0 sm:p-5"
        >
          <Img src="images/img_trash.svg" />
        </Button>
      </div>
    </div>
  );
}
