import React from "react";
import { Helmet } from "react-helmet";
import { Text, Img, Heading , } from "../components";
import { Link, Navigate } from "react-router-dom";
const NotFound = () => {
  return (
    <>
        <Navigate to="/" />
        <Helmet>
          <title>ReCut</title>
          <meta name="description" content="Visionnage, téléchargement et utilisation des contenus mis à disposition sur la plateforme ReCut." />
        </Helmet>
        <div className="relative h-[100vh] w-full md:h-[100vh]">
          
          <div >
            <div className="container-xs mb-[611px] px-[445px] md:p-5 md:px-5">
              

      <div class="MuiBox-root css-16puer9"></div>
      <div class="MuiBox-root css-fredw7"></div>
      <div class="MuiBox-root css-awh48z"></div>
      <div class="MuiBox-root css-11tkosz"></div>
      <div class="MuiBox-root css-x2gc8h"></div>
      <div class="MuiBox-root css-1x7vn5a"></div>
      <div class="MuiBox-root css-1belss4"></div>
              <div>
                <div className="flex flex-col items-center">
                  <Img src="/images/img_settings.svg" alt="headerlogo" className="h-[50px] w-[88px] md:mt-[50px] object-contain  mt-[100px]" />
                  <Heading size="headline_2" as="h1" className="mt-[10px] md:mt-[100px] w-[100%] text-center leading-[60px]">
                    Page 404
                  </Heading>
                  <Text as="p" className="mt-[10px] w-full text-center leading-6">
                    Désolé, la page que vous recherchez n'existe pas.
                  </Text>
                  <Link to='/' >
                    <Text as="p" className="mt-14">
                      Accueil
                    </Text>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  );
};

export default NotFound;
