import React, { useState, useEffect, Suspense } from "react";
import { Helmet } from "react-helmet";
import { Text, Heading, Button, Img, SelectBox, Input } from "../../components";
import FilmCardsquence from "../../components/FilmCardsquence";
import { Navigate, useParams } from "react-router-dom";
import { getFilm , getSequencesByFilm} from "services/filmsServices";
import { isLogin } from "services/authServices";
import AdminGestionPageDistributeurNavbar from "components/AdminGestionPageDistributeurNavbar";
import { Link } from "react-router-dom";
import Footer from "components/Footer";
import { getFilms, getSequencesHome } from '../../services/filmsServices';
import { CloseSVG } from "assets/images";

export default function FilmPage() {
  const [searchBarValue, setSearchBarValue] = useState("");
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [films, setFilms] = useState([]);
  const [isOpenChallengeModal, setIsOpenChallengeModal] = useState(false);
  const isSecondRow = (index) => Math.floor(index / 4) === 1;
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [durations, setDurations] = useState({});
  const appUrl = process.env.REACT_APP_URL;
  
  const fetchVideos = async () => {
    try {
      const videoData = await getSequencesHome(searchBarValue);
      setVideos(videoData.sequences);
    } catch (error) {
      console.error("Error fetching videos:", error);
    }
  };
    
  const fetchFilms = async () => {
    try {
      const filmsData = await getFilms();
      setFilms(filmsData.films);
    } catch (error) {
      console.error("Error fetching films:", error);
    }
  };

  useEffect(() => {
    fetchFilms();
    fetchVideos();
    // Scroll to the top
    //window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    fetchVideos();
  }, [searchBarValue]);

  const handleButtonCloseClick = async () => {
    setIsOpenChallengeModal(!isOpenChallengeModal);
  };


  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Helmet>
        <title>ReCut</title>
        <meta name="description" content="Visionnage, téléchargement et utilisation des contenus mis à disposition sur la plateforme ReCut." />
        <meta property="og:title" content="ReCut" />
        <meta property="og:description" content="Visionnage, téléchargement et utilisation des contenus mis à disposition sur la plateforme ReCut." />
        <meta property="og:image" content="https://recut.fr/images/logo-recut.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="ReCut" />
      </Helmet>
      <div className="w-full">
        <div className="flex flex-col items-center gap-2.5">
          <div className="relative self-stretch ">
            <div className="flex w-full flex-col items-center">
              <div className="flex items-center self-stretch md:flex-col">
                <div className="relative w-[100%] md:h-auto md:w-full">
                  <div className="w-full">
                    <div
                      className="bg-cover bg-no-repeat pb-[55px]  md:pb-5"
                    >
                      <div className="flex flex-col">
                        <AdminGestionPageDistributeurNavbar />
                        <div className="container-xs md:p-5">
                          <div className="flex flex-col items-start gap-[33px]">
                           
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="relative mt-[-37px] flex flex-col items-center bg-no-repeat p-[11px] md:h-auto">
                      <div className="w-[91%] md:w-full">
                        <div className="container-xs flex flex-col items-start gap-[27px] md:p-5">
                          <div className="flex flex-col w-[100%] gap-[54px] sm:gap-[27px]">
                            <div className="container-xs flex flex-col items-start">
                              <Heading size="headline_3" as="h3">
                                Nos derniers contenus
                              </Heading>
                            </div>
                            <div className="container-xs ">
                            <div className="flex mt-3 w-[78%] mb-5 gap-5 sm:w-[100%] sm:flex-row sm:overflow-scroll">
                                 <Suspense fallback={<div className="loader">Loading feed...</div>}>
                                    {Array.isArray(films) && films.length > 0 ? (
                                    films.map((film, index) => (
                                      <Link to={"/"+film.pseudo} className="w-[150px]">
                                        <div className="mb-3">
                                          <Img src={appUrl+"public/films/"+film.id+"-affiche.jpg"} alt={film.title} className="h-[200px] w-[150px] object-cover rounded-[10px] max-w-max" />
                                        </div>
                                        <div className="flex flex-1 flex-col items-start gap-2 sm:gap-1">
                                          <Heading size="subtitle_3" as="p" className="sm:text-sm limit-texte">
                                            {film.title}
                                          </Heading>
                                          <Link to={"/"+film.pseudo}>
                                            <Text as="p" className="!text-gray-500 sm:text-xs limit-texte">
                                              {film.sequenceCount} séquences
                                            </Text>
                                          </Link>
                                        </div>
                                      </Link>
                                    ))
                                  ) : (
                                    <Text as="p" className="text-white sm:text-xs">No films available</Text>
                                  )}
                                  </Suspense>
                              </div>
                            </div> 
                          </div>
                          <div className="flex flex-col w-[100%] gap-[54px] sm:gap-[27px]">
                            <div className="container-xs flex flex-col items-start grid grid-cols-3 gap-4">
                              <Heading size="headline_3" as="h3" className="col-span-2 sm:col-span-8">
                                Séquences tendances
                              </Heading>
                              <Input
                                color="gray_900"
                                size="xl"
                                name="search"
                                placeholder={"Rechercher une séquence"}
                                value={searchBarValue}
                                onChange={(e) => setSearchBarValue(e.target.value)}
                                prefix={
                                  <Img src="images/img_rewind.svg" alt="rewind" className="h-[26px] w-[26px] cursor-pointer" />
                                }
                                suffix={
                                  searchBarValue?.length > 0 ? (
                                    <CloseSVG
                                      onClick={() => setSearchBarValue("")}
                                      height={26}
                                      width={26}
                                      fillColor="#ffffffff"
                                    />
                                  ) : null
                                }
                                className="flex-grow gap-[23px]  rounded-[26px] text-gray-500 sm:pr-5 sm:col-span-8"
                              />
                            </div>
                            <div className="container-xs mb-[100px]">
                              <div className="grid mt-3 grid-cols-4 gap-4 sm:grid-cols-1 sm:gap-12">
                               {Array.isArray(videos) && videos.length > 0 ? (
                                  videos.map((video, index) => (
                                    <div
                                    key={index}
                                    className={`relative w-full ${[0, 2, 5, 7].includes(index) ? 'h-[300px] sm:h-[250px] sm:mt-[10px]' : 'h-[150px] sm:h-[250px] sm:mt-[10px]'} ${[5, 7].includes(index) ? 'mt-[-145px] sm:mt-[10px]' : ''}`}
                                    style={isSecondRow(index) ? { top: window.innerWidth >= 640 ? "46px" : "0px", position: "relative" } : {}}
                                  >
                                  
                                      <div className="videofilmrecut h-full w-full overflow-hidden rounded-[10px] sm:rounded-[5px] flex justify-center">
                                      <Link
                                        to={isLogin()?"/show/" + video.id:"/connexionseaux?sequence="+ video.id}
                                        className="masquesurvideo"
                                        style={{ height: [0, 2, 5, 7].includes(index) ? "300px" : "170px" }}
                                      ></Link>
                                        <Img
                                            src={appUrl+video.urlimage}
                                            alt="image"
                                            className="w-full h-full object-cover rounded-[7px] sm:rounded-[3.5px]"
                                          />
                                      </div>
                                      <div className="absolute right-[9px] top-[9px] flex w-[41%] justify-end gap-[5px]">
                                      
                                        <Text as="p" className="flex items-center justify-center rounded bg-black-900_bf px-[5px] py-1 sm:px-2.5 sm:py-0.5">
                                        {durations[index] || video.timesequence}
                                        </Text>
                                      </div>
                                      <div className="flex w-full items-start justify-top gap-[15px] blockinfovideo sm:gap-[7.5px]">
                                      
                                          <Img src={"images/logo-"+(video.producteur=="" || video.producteur ==null?video.film.producteur:video.producteur)+"-sequences.png"} className="w-[30px] !rounded-[14px]" />
                                        
                                        <div className="flex flex-1 flex-col items-start gap-2 sm:gap-1 w-[80%]">
                                          <Heading size="subtitle_3" as="h1" className="sm:text-sm limit-texte">
                                            {video.title}
                                          </Heading>
                                          <Link to={video.film.pseudo!=""?"/"+video.film.pseudo:"/film/"+video.filmId}>
                                            <Text as="p" className="!text-gray-500 sm:text-xs limit-texte">
                                              {video.subtitle}
                                            </Text>
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                ) : (
                                  <Text as="p" className="text-white sm:text-xs">No videos available</Text>
                                )}
                              </div>
                            </div> 
                          </div>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}


// import React, { Suspense, useState, useRef, useEffect } from "react";
// import { Helmet } from "react-helmet";
// import { CloseSVG } from "../../assets/images";
// import { Text, Heading, Button, Img, SelectBox, Input } from "../../components";
// import BrowseRowsportOne from "../../components/BrowseRowsportOne";
// import VideoPlayer from '../../components/VideoPlayer';
// import { getFilms, getSequencesHome } from '../../services/filmsServices';
// import { Link, Navigate } from "react-router-dom";
// import MenuClient from "components/MenuClient";
// import Footer from "components/Footer";
// import { isLogin } from "services/authServices";

// export default function BrowsePage() {
//   //return <Navigate to="/film/1" />;
//   const [searchBarValue, setSearchBarValue] = useState("");
//   const [videos, setVideos] = useState([]);
//   const [films, setFilms] = useState([]);
//   const [hoveredIndex, setHoveredIndex] = useState(null);
//   const [durations, setDurations] = useState({});
//   const appUrl = process.env.REACT_APP_URL;
//   const isSecondRow = (index) => Math.floor(index / 4) === 1;

//   useEffect(() => {
//     const fetchVideos = async () => {
//       try {
//         const videoData = await getSequencesHome(searchBarValue);
//         setVideos(videoData.sequences);
//       } catch (error) {
//         console.error("Error fetching videos:", error);
//       }
//     };

//     const fetchFilms = async () => {
//       try {
//         const filmsData = await getFilms();
//         setFilms(filmsData.films);
//       } catch (error) {
//         console.error("Error fetching films:", error);
//       }
//     };
//     fetchFilms();
//     fetchVideos();
//   }, [searchBarValue]);

//   const handleMouseEnter = (index) => {
//     setHoveredIndex(index);
//   };

//   const handleMouseLeave = () => {
//     setHoveredIndex(null);
//   };

//   return (
//     <>
      
//       <Helmet>
//         <title>ReCut</title>
//         <meta name="description" content="Visionnage, téléchargement et utilisation des contenus mis à disposition sur la plateforme ReCut." />
//       </Helmet>
//       <div className="relative w-full ">
//         <div className="bottom-0 left-0 right-0 top-0 m-auto w-full md:h-auto">
//           <div className="mb-[100px] left-0 right-0 top-0 m-auto flex h-max w-full flex-col items-center gap-[49px]">
//             <header className="flex items-end justify-center self-stretch py-[15px]">
//               <div className="container-xs mt-2.5 flex justify-center md:p-5">
//                 <div className="flex w-full items-end md:items-end justify-between md:justify-center gap-5 md:flex-wrap">
//                   <Img src="images/logo-pathe2.png" alt="headerlogo" className="h-[60px] object-contain" />
//                   <div className="mb-[7px] flex w-[55%] justify-end gap-3.5 md:w-full md:flex-wrap">
//                     <Input
//                       color="gray_900"
//                       size="xl"
//                       name="search"
//                       placeholder={"Rechercher une séquence"}
//                       value={searchBarValue}
//                       onChange={(e) => setSearchBarValue(e.target.value)}
//                       prefix={
//                         <Img src="images/img_rewind.svg" alt="rewind" className="h-[26px] w-[26px] cursor-pointer" />
//                       }
//                       suffix={
//                         searchBarValue?.length > 0 ? (
//                           <CloseSVG
//                             onClick={() => setSearchBarValue("")}
//                             height={26}
//                             width={26}
//                             fillColor="#ffffffff"
//                           />
//                         ) : null
//                       }
//                       className="flex-grow gap-[23px]  rounded-[26px] text-gray-500 sm:pr-5"
//                     />
//                     {isLogin() &&  <MenuClient />}
//                   </div>
//                 </div>
//               </div>
//             </header>
//             <div className="container-xs md:p-5">
//               <Heading size="headline_3" as="h4">
//                 Les films du moment
//               </Heading>
//               <div className="flex mt-3 w-[78%] mb-5 gap-5 sm:w-[100%] sm:flex-row sm:overflow-scroll">
//                   {/* <Suspense fallback={<div className="loader">Loading feed...</div>}>
//                     {data.map((d, index) => (
//                       <BrowseRowsportOne {...d} key={"listsportone" + index} />
//                     ))}
//                   </Suspense> */}
//                   {Array.isArray(films) && films.length > 0 ? (
//                     films.map((film, index) => (
//                       <Link to={"/film/"+film.id} className="w-[150px]">
//                         <div className="mb-3">
//                           <Img src={appUrl+"public/films/"+film.id+"-affiche.jpg"} alt={film.title} className="h-[200px] w-[150px] object-cover rounded-[10px] max-w-max" />
//                         </div>
//                         <div className="flex flex-1 flex-col items-start gap-2 sm:gap-1">
//                           <Heading size="subtitle_3" as="p" className="sm:text-sm limit-texte">
//                             {film.title}
//                           </Heading>
//                           <Link to={"/film/"+film.id}>
//                             <Text as="p" className="!text-gray-500 sm:text-xs limit-texte">
//                               {film.sequenceCount} séquences
//                             </Text>
//                           </Link>
//                         </div>
//                       </Link>
//                     ))
//                   ) : (
//                     <Text as="p" className="text-white sm:text-xs">No films available</Text>
//                   )}
//               </div>
//               <Heading size="headline_3" as="h4">
//                 Séquences tendance
//               </Heading>
//               <div className="grid mt-3 grid-cols-4 gap-4 sm:grid-cols-1 sm:gap-12">
//                 {Array.isArray(videos) && videos.length > 0 ? (
//                   videos.map((video, index) => (
//                     <div
//                     key={index}
//                     className={`relative w-full ${[0, 2, 5, 7].includes(index) ? 'h-[300px] sm:h-[250px] sm:mt-[10px]' : 'h-[150px] sm:h-[250px] sm:mt-[10px]'} ${[5, 7].includes(index) ? 'mt-[-145px] sm:mt-[10px]' : ''}`}
//                     onMouseEnter={() => handleMouseEnter(index)}
//                     onMouseLeave={handleMouseLeave}
//                     style={isSecondRow(index) ? { top: window.innerWidth >= 640 ? "46px" : "0px", position: "relative" } : {}}
//                   >
                  
//                       <div className="videofilmrecut h-full w-full overflow-hidden rounded-[10px] sm:rounded-[5px] flex justify-center">
//                       <Link
//                         to={isLogin()?"/show/" + video.id:"/connexionseaux?sequence="+ video.id}
//                         className="masquesurvideo"
//                         style={{ height: [0, 2, 5, 7].includes(index) ? "300px" : "170px" }}
//                       ></Link>
//                         <Img
//                             src={appUrl+video.urlimage}
//                             alt="image"
//                             className="w-full h-full object-cover rounded-[7px] sm:rounded-[3.5px]"
//                           />
//                       </div>
//                       <div className="absolute right-[9px] top-[9px] flex w-[41%] justify-end gap-[5px]">
                       
//                         <Text as="p" className="flex items-center justify-center rounded bg-black-900_bf px-[5px] py-1 sm:px-2.5 sm:py-0.5">
//                         {durations[index] || video.timesequence}
//                         </Text>
//                       </div>
//                       <div className="flex w-full items-start justify-top gap-[15px] blockinfovideo sm:gap-[7.5px]">
                       
//                           <Img src="images/logo-pathe2.png" className="w-[30px] !rounded-[14px]" />
                        
//                         <div className="flex flex-1 flex-col items-start gap-2 sm:gap-1 w-[80%]">
//                           <Heading size="subtitle_3" as="h1" className="sm:text-sm limit-texte">
//                             {video.title}
//                           </Heading>
//                           <Link to={"/film/"+video.filmId}>
//                             <Text as="p" className="!text-gray-500 sm:text-xs limit-texte">
//                               {video.subtitle}
//                             </Text>
//                           </Link>
//                         </div>
//                       </div>
//                     </div>
//                   ))
//                 ) : (
//                   <Text as="p" className="text-white sm:text-xs">No videos available</Text>
//                 )}
//               </div>
//             </div>
//           </div>
//           <Footer />
//         </div>
//       </div>
//     </>
//   );
// }
