import React from "react";

const sizes = {
  button_1_medium: "font-inter text-base font-medium",
  button_1_medium1: "text-base font-bold",
  headline_3: "tracking-[-0.62px] text-[31px] font-bold md:text-[29px] sm:text-[27px]",
  headline_2: "tracking-[-0.96px] text-5xl font-medium md:text-[44px] sm:text-[38px]",
  subtitle_1: "text-base font-bold",
  headline_6: "text-xl font-medium",
  headline_1: "tracking-[-1.20px] text-6xl font-medium md:text-[52px] sm:text-[46px]",
  textxs: "text-sm font-medium",
};

const Heading = ({ children, className = "", size = "headline_6", as, ...restProps }) => {
  const Component = as || "h6";

  return (
    <Component className={`text-default-white font-leaguespartan ${className} ${sizes[size]}`} {...restProps}>
      {children}
    </Component>
  );
};

export { Heading };
