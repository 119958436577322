import axios from 'axios';
import apiClient from '../axios-interceptor';

const apiUrl = process.env.REACT_APP_API_URL+"/users";

// Function to get UserSequence by connected user
export const getUserSequences = async () => {
  try {
    const response = await apiClient.get(apiUrl+'/sequences'); // Adjust endpoint as necessary
    return response.data.userSequences;
  } catch (error) {
    console.error('Error fetching user sequences:', error);
    throw error;
  }
};

export async function getDureeUserSequences(idsequence,filmId) {
  try {
    const response = await apiClient.get(apiUrl+'/dureesequences/'+idsequence+'/'+filmId); // Adjust endpoint as necessary
    return response.data;
  } catch (error) {
    console.error('Error fetching user sequences:', error);
    throw error;
  }
}


