import React, { useState, useEffect, Suspense } from "react";
import { Helmet } from "react-helmet";
import { Text, Heading, Button, Img } from "../../components";
import FilmCardsquence from "../../components/FilmCardsquence";
import { Navigate, useParams } from "react-router-dom";
import { getFilm , getSequencesByFilm} from "services/filmsServices";
import { isLogin } from "services/authServices";
import AdminGestionPageDistributeurNavbar from "components/AdminGestionPageDistributeurNavbar";
import { Link } from "react-router-dom";
import Footer from "components/Footer";

export default function FilmPage() {
  const { id,pseudofilm } = useParams();
  const [film, setFilm] = useState(null);
  const [loading, setLoading] = useState(true);
  const [videosByFilm, setVideosByFilm] = useState([]);
  const [producteur, setProducteur] = useState("pathe");
  const [isOpenChallengeModal, setIsOpenChallengeModal] = useState(false);
  // const isSecondRow = (index) => Math.floor(index / 4) === 1;
  // const [hoveredIndex, setHoveredIndex] = useState(null);
  // const [durations, setDurations] = useState({});
  
  const appUrl = process.env.REACT_APP_URL;
  useEffect(() => {
    const fetchData = async (id) => {
      try {
        const videoData = await getFilm(id);
        setFilm(videoData.film);
        setProducteur(videoData.film.producteur);
        fetchVideosByFilm(videoData.film.id)
      } catch (error) {
        console.error("Error fetching videos:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchVideosByFilm = async (id) => {
      try {
        const videoData = await getSequencesByFilm(id);
        console.log(videoData);
        console.log(videoData.sequences);
        setVideosByFilm(videoData.sequences);
       
      } catch (error) {
        console.error("Error fetching videos:", error);
      }
    };

    if(!isNaN(Number(id))){
      fetchData(id);
    }else{
      fetchData(pseudofilm);
    }
    // Scroll to the top
    window.scrollTo(0, 0);
  }, [id]);

  // const handleMouseEnter = (index) => {
  //   setHoveredIndex(index);
  // };

  // const handleMouseLeave = () => {
  //   setHoveredIndex(null);
  // };

  // const handleDuration = (index, duration) => {
  //   setDurations((prevDurations) => ({
  //     ...prevDurations,
  //     [index]: duration,
  //   }));
  // };

  const handleButtonCloseClick = async () => {
    setIsOpenChallengeModal(!isOpenChallengeModal);
  };
  
  if (loading) {
    return <div>Loading...</div>;
  }

  if (!film) {
    return <Navigate to="/404" />;
  }

  return (
    <>
      <Helmet>
        <title>ReCut - {film && film.title ? film.title : ""}</title>
        <meta name="description" content={film && film.desc ? film.desc : ""} />
        <meta property="og:title" content={"ReCut - " + (film && film.title ? film.title : "ReCut")} />
        <meta property="og:description" content={film && film.desc ? film.desc : ""} />
        <meta property="og:image" content={film && film.id ? `${appUrl}public/films/${film.id}.jpg` : `${appUrl}images/logo-recut.png`} />
        <meta property="og:url" content={`${appUrl}film/${film.id}`} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={"ReCut - " + (film && film.title ? film.title : "ReCut")} />
      </Helmet>
      <div className="w-full">
        <div className="flex flex-col items-center gap-2.5">
          <div className="relative self-stretch ">
            <div className="flex w-full flex-col items-center">
              <div className="flex items-center self-stretch md:flex-col">
                <div className="relative w-[100%] md:h-auto md:w-full">
                  <div className="w-full">
                    {/* <div
                      className="h-[548px] bg-cover bg-no-repeat pb-[55px] md:h-auto md:pb-5"
                      style={{ backgroundImage: `url(${appUrl+film.urlimage})` }}
                    > */}
                    <div
                      className="sizebannierefilm bg-cover bg-no-repeat pb-[55px]  md:pb-5"
                      style={{

                        backgroundImage: `
                          linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)),
                          url(${appUrl+film.urlimage})
                        `
                      }}
                    >
                      <div className="flex flex-col items-center gap-[289px] md:gap-[216px] sm:gap-36">
                        <AdminGestionPageDistributeurNavbar producteur={producteur}/>
                        <div className="container-xs md:p-5">
                          <div className="flex flex-col items-start gap-[33px]">
                           
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="relative mt-[-350px] sm:mt-[-0px] flex flex-col items-center bg-no-repeat p-[11px] md:h-auto" >
                      <div className="w-[91%] md:w-full">
                        <div className="container-xs flex flex-col items-start gap-[27px] md:p-5">
                          <div className="flex flex-wrap">
                            <div className="flex flex-col mb-[20px] gap-[51px] sm:gap-[25px] w-[50%] md:w-[100%]">
                              <div className="flex">
                                <div>
                                  <Heading size="headline_2" as="h2">
                                    {film.title}
                                  </Heading>
                                  <Text as="p">{film.subtitle}</Text>
                                </div>
                                {/* <div className="iconpatheinpagefilm">
                                  <Img
                                    alt="e5"
                                    className="w-[70px]"
                                    src="/images/logo-pathe.png"
                                  />
                                </div> */}
                              </div>
                              
                              <Text as="p" className="w-[100%] leading-6 md:w-full md:p-5">
                                {film.desc}
                              </Text>
                              {/* <Img
                                alt="e5"
                                className="e3svgFilm sm:hidden"
                                src="/images/background/e5.svg"
                                style={{
                                  top: '-12%' ,
                                  right : '50px',
                                  position: 'absolute'
                                }}
                              /> */}
                              <div className="flex sm:justify-center gap-[9px]">
                                <Link to={film.producteur!="pathe"?"/conditions-generales-utilisation-"+film.producteur:"/conditions-generales-utilisation"}>
                                  <Button
                                    color="default_white"
                                    size="md"
                                    variant="outline"
                                    shape="round"
                                    leftIcon={
                                      <Img
                                        src="/images/img_documenttext.svg"
                                        alt="document-text"
                                        className="h-[16px] w-[16px]"
                                      />
                                    }
                                    className="min-w-[168px] gap-1.5 font-gtamerica"
                                  >
                                    CGU
                                  </Button>
                                </Link>
                                {/* <Button
                                  color="default_white"
                                  size="md"
                                  variant="outline"
                                  shape="round"
                                  leftIcon={
                                    <Img
                                      src="/images/img_documenttext.svg"
                                      alt="document-text"
                                      className="h-[16px] w-[16px]"
                                    />
                                  }
                                  className="min-w-[175px] gap-1.5 font-gtamerica"
                                >
                                  Conditions d’exploitation
                                </Button> */}
                              </div>
                              {/* <Heading as="h2">Synopsis</Heading> */}
                            
                            </div>
                            {film && film.challenge && (
                              <div className="flex flex-col gap-[50px] z-40 items-end md:items-center justify-cente sm:gap-[25px] w-[50%] md:w-[100%] challenge">
                                <div className="text-white p-6 rounded-xlg shadow-lg max-w-md challenge-block" style={{borderColor:film.challenge.color}}>
                                  <h1 className="text-2xl font-bold mb-4 text-center">{film.challenge.title}</h1>
                                  <p className="text-lg mb-4 text-center">
                                    {film.challenge.subtitle}
                                    <br />
                                    {film.challenge.date}
                                  </p>

                                  {/* Use dangerouslySetInnerHTML to safely render HTML */}
                                  <div className="challengesubdesc" dangerouslySetInnerHTML={{ __html: film.challenge.subdesc }} />

                                  <div className="flex justify-center space-x-4">
                                    <a
                                      href="https://www.tiktok.com/@recut_app?_t=8njreN74mUi&_r=1"
                                      target="_blank"
                                      rel="noreferrer"
                                      className="play-icon"
                                      style={{backgroundColor:film.challenge.color}}
                                    >
                                      <div className="triangle"></div>
                                    </a>
                                    <div className="document-icon" onClick={setIsOpenChallengeModal} style={{backgroundColor:film.challenge.color}}>
                                      <Img
                                        src="/images/img_documenttext.svg"
                                        alt="document-text"
                                        className="h-[30px] w-[30px]"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          
                          <div className="flex flex-col w-[100%] gap-[54px] sm:gap-[27px]">
                            <div className="container-xs flex flex-col items-center md:p-5">
                              <Heading size="headline_3" as="h3">
                                Séquences
                              </Heading>
                            </div>
                            <div className="container-xs grid grid-cols-4 gap-4 md:grid-cols-2 sm:grid-cols-1 md:gap-6 sm:gap-12">
                              <Suspense fallback={<div className="loader">Loading feed...</div>}>
                                {videosByFilm?.map((video, index) => (
                                  <FilmCardsquence 
                                  key={"listtime" + index} 
                                  filmId={video.filmId} 
                                  scneomondain={video.title} 
                                  leschoristes={video.subtitle==""||video.subtitle==null?video.film.title:video.subtitle} 
                                  urlimage={video.urlimage} 
                                  timesequence={video.timesequence}
                                  idsequence={video.id}
                                  afficherTelechargement={false}
                                  producteur={video.producteur=="" || video.producteur ==null?video.film.producteur:video.producteur } 
                                  pseudofilm={video.film.pseudo}
                                  className=" md:gap-[13px]" 
                                  />
                                ))}
                              </Suspense> 
                            </div> 
                            {/* 
                            <div className="grid grid-cols-4 gap-4 sm:grid-cols-1 sm:gap-12">
                                {Array.isArray(videosByFilm) && videosByFilm.length > 0 ? (
                                  videosByFilm.map((video, index) => (
                                    <div
                                    key={index}
                                    className={`relative w-full ${[0, 2, 5, 7].includes(index) ? 'h-[300px] sm:h-[250px] sm:mt-[10px]' : 'h-[150px] sm:h-[250px] sm:mt-[10px]'} ${[5, 7].includes(index) ? 'mt-[-145px] sm:mt-[10px]' : ''}`}
                                    onMouseEnter={() => handleMouseEnter(index)}
                                    onMouseLeave={handleMouseLeave}
                                    style={isSecondRow(index) ? { top: window.innerWidth >= 640 ? "46px" : "0px", position: "relative" } : {}}
                                  >
                                  
                                      <div className="videofilmrecut h-full w-full overflow-hidden rounded-[10px] sm:rounded-[5px] flex justify-center">
                                      <Link
                                        to={"/show/" + video.id}
                                        className="masquesurvideo"
                                        style={{ height: [0, 2, 5, 7].includes(index) ? "300px" : "170px" }}
                                      ></Link>
                                        {hoveredIndex === index ? (
                                          <VideoPlayer src={"public/hls/sequence_"+video?.id+"/index.m3u8"} play={true} height={[0, 2, 5, 7].includes(index) ? 300:170} onDuration={(duration) => handleDuration(index, duration)} />
                                        ) : (
                                          <Img
                                            src={appUrl+video.urlimage}
                                            alt="image"
                                            className="w-full h-full object-cover rounded-[7px] sm:rounded-[3.5px]"
                                          />
                                        )}
                                      </div>
                                      <div className="absolute right-[9px] top-[9px] flex w-[41%] justify-end gap-[5px]">
                                        <Text as="p" className="flex items-center justify-center rounded bg-black-900_bf px-[5px] py-1 sm:px-2.5 sm:py-0.5">
                                        {durations[index] || video.timesequence}
                                        </Text>
                                      </div>
                                      <div className="flex w-full items-start justify-top gap-[15px] blockinfovideo sm:gap-[7.5px]">
                                        <Button shape="circle" className="w-[28px] ">
                                          <Img src="/images/img_linkedin.svg" className="" />
                                        </Button>
                                        <div className="flex flex-1 flex-col items-start gap-2 sm:gap-1">
                                          <Heading size="subtitle_3" as="h1" className="sm:text-sm">
                                            {video.title}
                                          </Heading>
                                          <Link to={"/film/"+video.filmId}>
                                            <Text as="p" className="!text-gray-500 sm:text-xs">
                                              {video.subtitle}
                                            </Text>
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                ) : (
                                  <Text as="p" className="text-white sm:text-xs">No videos available</Text>
                                )}
                              </div>*/}
                          </div>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer producteur={producteur}/>
      {isOpenChallengeModal && film.challenge && (              
        <div
          id="challenge-modal"
          tabIndex="-1"
          aria-hidden="true"
          className="fixed inset-0 z-50 flex items-baseline justify-center w-full h-full bg-black bg-opacity-50 overflow-y-auto overflow-x-auto"
        >
          <div className="challenge-modal relative p-4 md:p-1 w-full max-w-[70%] md:max-w-[95%]" style={{borderColor:film.challenge.color}}>
            {/* Modal content */}
            <div className="challenge-modal-body-bg-image" 
              style={{
                backgroundImage: `url(${appUrl + film.challenge.bgimage})`
              }}>
              <div className="challenge-modal-body relative bg-white rounded-lg shadow dark:bg-gray-700">
                {/* Modal body */}
                <div className="p-4 md:p-1 md:p-5 space-y-4">
                  <div className="absolute top-3 right-3">
                    <div className="close-button" onClick={handleButtonCloseClick}></div>
                  </div>
                
                  <div className="text-center md:pt-[40px]">
                    <h1 className="text-3xl font-bold">{film.challenge.popuptitle}</h1>
                    <p className="text-xl">{film.challenge.popupdate}</p>
                  </div>
                  <div className="bg-white p-6 pt-0 rounded-lg shadow-md md:pb-[60px] challengesubdesc" dangerouslySetInnerHTML={{ __html: film.challenge.popupdesc }} />
                      {/* <h2 className="text-2xl font-semibold mb-2">1. Participation :</h2>
                      <div className="mb-2">
                          <ul className="list-disc list-inside ml-4">
                            <li className="font-semibold">Participer en Solo :</li>
                              <ul className="list-[circle] list-inside ml-4">
                                <li>Mettre le #ChallengeMonteCristo dans la description du contenu posté</li>
                              </ul>
                            <li className="font-semibold">Participer en Équipe :</li>
                              <ul className="list-[circle] list-inside ml-4">
                                  <li>Mettre le #ChallengeMonteCristo dans la description du contenu posté</li>
                                  <li>Mettre le #[Équipe] dans la description du contenu posté (Max. 1 #Équipe par vidéo)</li>
                                  <li>Droit de poster plusieurs vidéos et d'avoir des #[Équipe] différents</li>
                              </ul>
                          </ul>
                      </div>
                      <h2 className="text-2xl font-semibold mb-2">2. Types de vidéo :</h2>
                      <p className="mb-2">Concours en expression libre avec ou sans les extraits fournis</p>
                      <h2 className="text-2xl font-semibold mb-2">3. Types de contenu :</h2>
                      <p className="mb-2">Tous les types de contenu respectant les droits d’auteur sont acceptés. Seuls les extraits fournis par Recut sont autorisés par Pathé</p>
                      <h2 className="text-2xl font-semibold mb-2">4. Nombre de contenus postés :</h2>
                      <p className="mb-2">Aucune limite</p>
                      <h2 className="text-2xl font-semibold mb-2">5. Durée de la vidéo :</h2>
                      <p className="mb-2">Libre</p>
                      <h2 className="text-2xl font-semibold mb-2">6. Vainqueurs :</h2>
                      <div className="mb-2">
                        <ul className="list-disc list-inside ml-4">
                          <li className="font-semibold">Les prix médias sociaux :</li>
                          <ul className="list-[circle] list-inside ml-4">
                              <li>Les 5 tiktok avec le plus grand ratio de vues/likes</li>
                              <li>Les 3 shorts Instagram avec le plus grand ratio de vues/likes</li>
                              <li>Les 2 tweets avec le plus grand nombre de vues</li>
                          </ul>
                          <li className="font-semibold">Les prix du jury :</li>
                          <ul className="list-[circle] list-inside ml-4">
                              <li>La composition du jury sera annoncée pendant le challenge</li>
                              <li>Chaque membre du jury décernera son prix</li>
                          </ul>
                          <li className="font-semibold">Les prix du jury :</li>
                          <ul className="list-[circle] list-inside ml-4">
                              <li>La composition du jury sera annoncée pendant le challenge</li>
                              <li>Chaque membre du jury décernera son prix</li>
                          </ul>
                          <li className="font-semibold">Les prix équipe :</li>
                          <ul className="list-disc list-inside ml-4">
                              <li>Les 3 équipes qui réuniront le plus de vues sur leur # seront récompensées (Score établi sur la base des 10 vidéos les plus vues par #)</li>
                          </ul>
                        </ul>
                      </div>
                    
                      <h2 className="text-2xl font-semibold mb-2">7. Prix :</h2>
                      <ul className="list-disc list-inside ml-4">
                          <li>50x2 places pour Emilia Perez</li>
                          <li>3x2 places en avant-première pour Monsieur Aznavour</li>
                          <li>Creator Cards (Embargo)</li>
                      </ul> */}
                
                  <a className="video-button absolute bottom-3 right-3" href={film.challenge.urlvideo} target="_blank" rel="noreferrer" style={{backgroundColor:film.challenge.color}}>
                      VIDÉO
                  </a>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      )}
    </>
  );
}
